import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ['loanAgreementId', 'progressText', 'progressIcon', 'continueButton']

  connect() {
    if (this.hasContinueButtonTarget) {
      this.continueButtonTarget.style.display = 'none'
    }

    let externalContext = this
    consumer.subscriptions.create({
      channel: 'ComplianceChannel',
      id: this.loanAgreementId()
    }, {
      received(data) {
        if (data.passed == true) {
          externalContext.approvedCompliance()
        } else if (data.passed == false) {
          externalContext.rejectedCompliance()
        }
      }
    })
  }

  loanAgreementId() {
    return this.loanAgreementIdTarget.getAttribute('id')
  }

  approvedCompliance() {
    this.continueButtonTarget.style.display = 'block'
    this.progressTextTarget.innerHTML = "Your compliance checks were successul.<br/>Please continue to review and sign your agreement."
    this.progressIconTarget.classList.remove('has-text-grey-lighter')
    this.progressIconTarget.classList.add('has-text-success')
    this.progressIconTarget.innerHTML = "<i class='fas fa-2x fa-check-circle'></i>"
  }

  rejectedCompliance() {
    this.progressTextTarget.innerHTML = "Unfortunately we could not verify the information that you provided.<br/>If you require assistance, please contact " + this.metaContent('title') + " on " + this.metaContent('phone')
    this.progressIconTarget.classList.remove('has-text-grey-lighter')
    this.progressIconTarget.classList.add('has-text-danger')
    this.progressIconTarget.innerHTML = "<i class='fas fa-2x fa-exclamation-circle'></i>"
  }

  metaContent (name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element && element.content
  }
}
