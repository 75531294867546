import { Controller } from "stimulus"
import Rails from "@rails/ujs"
let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = [
    "interest", "fees", "insurance", "insuranceType", "totalRepayment", "sameAsPreviousDetails",
    "expenseEducation", "expenseGroceries", "expenseMaintenance", "expenseOther", "expenseOtherDetails",
    "expenseRent", "expenseTravel", "expenseUtilities", "expenseMedical", "expenseGarnisheeOrders",
    "expenseInsurance", "expenseCreditCards", "principal", "dueDate", "loanAgreementId", "continueButton",
    "expenseOtherDetailsField", "terms", "instalmentAmount", "firstInstalmentDate", "numberOfInstalments"
  ]

  initialize() {
    this.calculateFeesAndInterest = debounce(this.calculateFeesAndInterest, 1000).bind(this)
  }

  connect() {
    let externalContext = this
    if(this.hasExpenseOtherDetailsTarget) {
      externalContext.expenseOtherDetailsToggle()
    }
    if(this.hasPrincipalTarget) {
      this.calculateFeesAndInterest()
    }
  }

  calculateFeesAndInterest() {
    let formData = new FormData()
    formData.append('principal', this.principalTarget.value)
    if (this.hasDueDateTarget) {
      formData.append('due_date', this.dueDateTarget.value)
    }
    if (this.hasTermsTarget) {
      formData.append('terms', this.termsTarget.value)
    }
    if (this.hasLoanAgreementIdTarget) {
      formData.append('loan_agreement_id', this.loanAgreementIdTarget.dataset.id)
    }
    if (this.hasInsuranceTypeTarget) {
      this.insuranceTypeTargets.forEach((el) => {
        if (el.checked) {
          formData.append('insurance_type', el.value)
        }
        this.insuranceTarget.innerHTML = '&nbsp;<i class="fas fa-spinner fa-spin has-text-grey-light"></i>'
        this.continueButtonTarget.disabled = true
      })
    }
    let externalContext = this

    Rails.ajax({
      type: 'POST',
      url: '/loan_agreements/calculate_fees',
      data: formData,
      success: function (data) {
        externalContext.interestTarget.textContent = externalContext.randAmount(parseFloat(data.interest));
        externalContext.feesTarget.textContent = externalContext.randAmount(parseFloat(data.fees));
        externalContext.totalRepaymentTarget.textContent = externalContext.randAmount(parseFloat(data.total_repayment));
        if (externalContext.principalTarget.nextSibling) {
          externalContext.principalTarget.max = data.maximum_principal;
          externalContext.principalTarget.nextSibling.textContent = `maximum amount is ${externalContext.randAmount(data.maximum_principal)}`
        }
        if (externalContext.hasInstalmentAmountTarget) {
          externalContext.instalmentAmountTarget.textContent = externalContext.randAmount(parseFloat(Object.values(data.instalments)[0]))
          externalContext.firstInstalmentDateTarget.textContent = Object.keys(data.instalments)[0]
          externalContext.numberOfInstalmentsTarget.textContent = Object.keys(data.instalments).length
        }
        if (externalContext.hasInsuranceTarget) {
          externalContext.insuranceTarget.textContent = externalContext.randAmount(parseFloat(data.insurance))
          externalContext.continueButtonTarget.disabled = false
        }
      },
    })
  }

  randAmount(newText) {
    return parseFloat(newText).toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR', maximumFractionDigits: 0 });
  }

  setAffordabilityValues() {
    if (this.sameAsPreviousDetailsTarget.checked) {
      this.expenseEducationTarget.value = this.expenseEducationTarget.dataset.value
      this.expenseGroceriesTarget.value = this.expenseGroceriesTarget.dataset.value
      this.expenseMaintenanceTarget.value = this.expenseMaintenanceTarget.dataset.value
      this.expenseOtherTarget.value = this.expenseOtherTarget.dataset.value
      this.expenseOtherDetailsTarget.value = this.expenseOtherDetailsTarget.dataset.value
      this.expenseRentTarget.value = this.expenseRentTarget.dataset.value
      this.expenseTravelTarget.value = this.expenseTravelTarget.dataset.value
      this.expenseUtilitiesTarget.value = this.expenseUtilitiesTarget.dataset.value
      this.expenseMedicalTarget.value = this.expenseMedicalTarget.dataset.value
      this.expenseGarnisheeOrdersTarget.value = this.expenseGarnisheeOrdersTarget.dataset.value
      this.expenseInsuranceTarget.value = this.expenseInsuranceTarget.dataset.value
      this.expenseCreditCardsTarget.value = this.expenseCreditCardsTarget.dataset.value
    } else {
      this.expenseEducationTarget.value = '0'
      this.expenseGroceriesTarget.value = '0'
      this.expenseMaintenanceTarget.value = '0'
      this.expenseOtherTarget.value = '0'
      this.expenseOtherDetailsTarget.value = '0'
      this.expenseRentTarget.value = '0'
      this.expenseTravelTarget.value = '0'
      this.expenseUtilitiesTarget.value = '0'
      this.expenseMedicalTarget.value = '0'
      this.expenseGarnisheeOrdersTarget.value = '0'
      this.expenseInsuranceTarget.value = '0'
      this.expenseCreditCardsTarget.value = '0'
    }
  }

  expenseOtherDetailsToggle() {
    if(parseInt(this.expenseOtherTarget.value) > 0) {
      this.expenseOtherDetailsFieldTarget.hidden = false
    } else {
      this.expenseOtherDetailsTarget.value = ""
      this.expenseOtherDetailsFieldTarget.hidden = true
    }
  }
}
