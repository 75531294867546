import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ['loanAgreementId', 'progressText', 'progressBar', 'progressIcon', 'continueButton']

  initialize() {
    if (this.hasContinueButtonTarget) {
      this.continueButtonTarget.style.display = 'none'
    }
  }
  connect() {
    let externalContext = this

    this.fetchPrevetStatus();

    consumer.subscriptions.create({
      channel: 'PrevetChannel',
      id: this.loanAgreementId()
    }, {
      received(data) {
        if (data.state == 'error') {
          externalContext.erroredPrevet()
        } else if (data.passed == true) {
          externalContext.successfulPrevet()
        } else if (data.passed == false) {
          externalContext.unsuccessfulPrevet()
        }
      }
    })
  }

  loanAgreementId() {
    return this.loanAgreementIdTarget.getAttribute('id')
  }

  successfulPrevet() {
    this.continueButtonTarget.style.display = 'block'
    this.progressTextTarget.textContent = 'You have been pre-approved, subject to an affordability assessment.'
    this.progressBarTarget.parentNode.remove();
    this.progressIconTarget.classList.remove('has-text-grey-lighter')
    this.progressIconTarget.classList.add('has-text-success')
    this.progressIconTarget.innerHTML = "<i class='fas fa-2x fa-check-circle'></i>"
  }

  unsuccessfulPrevet() {
    this.progressTextTarget.textContent = "Unfortunately you do not qualify for a new loan. You can re-apply in 30 days."
    this.progressBarTarget.parentNode.remove();
    this.progressIconTarget.classList.remove('has-text-grey-lighter')
    this.progressIconTarget.classList.add('has-text-danger')
    this.progressIconTarget.innerHTML = "<i class='fas fa-2x fa-ban'></i>"
  }

  erroredPrevet() {
    this.progressTextTarget.textContent = `We experienced a problem while performing your credit check. Please contact ${this.tenantEmail()}`
    this.progressBarTarget.parentNode.remove();
    this.progressIconTarget.classList.remove('has-text-grey-lighter')
    this.progressIconTarget.classList.add('has-text-warning')
    this.progressIconTarget.innerHTML = "<i class='fas fa-2x fa-exclamation-circle'></i>"
  }

  tenantEmail() {
    return this.loanAgreementIdTarget.dataset.tenantEmail
  }

  fetchPrevetStatus() {
    let requestUrl = `/loan_agreements/${this.loanAgreementId()}/check_prevet`
    fetch(requestUrl, {
      method: 'post'
    })
    .then(response => response.json())
    .then(data => {
      if (data.state == 'error') {
        this.erroredPrevet()
      } else if (data.passed == true) {
        this.successfulPrevet()
      } else if (data.passed == false) {
        this.unsuccessfulPrevet()
      }
    })
  }
}
