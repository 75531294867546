import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["fileInput", "fileName", "navbarBurger", "navbarMenu", "slider", "datepicker" ]

  initialize() {
    if (this.hasDatepickerTarget) {
      this.updateDatepickerOutput()
    }
  }

  updateDatepickerOutput(event) {
    var output = this.datepickerTarget.closest('.field').getElementsByClassName('is-datepicker-output')[0]
    var today = new Date().setUTCHours(0, 0, 0, 0)
    var selected = new Date(this.datepickerTarget.value ? this.datepickerTarget.value : new Date().toLocaleDateString())
    var days = parseInt((selected - today) / (1000 * 3600 * 24))
    output.innerText = days
  }

  toggleNavbar() {
    this.navbarBurgerTarget.classList.toggle("is-active")
    this.navbarMenuTarget.classList.toggle("is-active")
  }

  closeNotification(event) {
    const notification = event.target.closest('.notification')
    notification.classList.add('fade-out')
    setTimeout(() => {
      notification.remove()
    }, 500);
  }

  openModal(event) {
    event.preventDefault()
    var modal = document.getElementById(event.target.dataset.target)
    modal.classList.add('is-active')
  }

  closeModal(event) {
    var modal = event.target.closest('.modal')
    modal.classList.remove('is-active')
  }

  updateSliderOutput(event) {
    var output = this.sliderTarget.closest('.field').getElementsByClassName('is-slider-output')[0]
    output.innerText = parseFloat(this.sliderTarget.value).toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR', maximumFractionDigits: 0 })
  }

  setFilename() {
    if (this.fileInputTarget.files.length > 0) {
      this.fileNameTarget.innerHTML = ""
      for (const file of this.fileInputTarget.files) {
        this.fileNameTarget.innerHTML += "<br>"
        this.fileNameTarget.innerHTML += file.name
      }
    }
  }
}
